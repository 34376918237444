@media (prefers-color-scheme: dark) {
  :root {
    --color-text: #f7f7f7;
    --color-text-field: #0e141f;
    --color-primary: #ffcb36;
    --color-link: #ffcb36;
    --color-header: var(--color-primary);
    --color-grey: #dfeaf1;
    --color-grey-light: #e7e7e7;
    --color-grey-dark: #7d7c7c;
    --color-blue: #216fad;
    --color-bg-primary: #0e141f;
    --color-text-primary: #0e141f;
    --color-text-secondary: var(--color-white);
    --color-blue-light: #0e65a9;
    --color-border: #233048;
    --color-danger-dark: #d70606;
    --color-warning-dark: #d79c06;
    --color-success-dark: #059725;
    --color-success: #0ee03c;
    --color-white: #ffffff;
    --shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.64);
  }
}
@media (prefers-color-scheme: light) {
  :root {
    --color-text: #0c0c0c;
    --color-text-field: #0e141f;
    --color-primary: #0e141f;
    --color-link: var(--color-danger-dark);
    --color-header: var(--color-primary);
    --color-grey: #dfeaf1;
    --color-grey-light: var(--color-grey-dark);
    --color-grey-dark: #bbc3d1;
    --color-blue: #216fad;
    --color-bg-primary: #e9f0fb;
    --color-text-primary: #ffcb36;
    --color-text-secondary: var(--color-text);
    --color-blue-light: #0e65a9;
    --color-border: #233048;
    --color-danger-dark: #d70606;
    --color-warning-dark: #d79c06;
    --color-success-dark: #059725;
    --color-success: #0ee03c;
    --color-white: #ffffff;
    --shadow: 0px 0px 9px 3px rgb(0 0 0 / 2%);
  }
}

body {
  font-size: 16px;
  background-color: var(--color-bg-primary);
}

.App {
  display: flex;
  height: 100vh;
}

.container{
  width: 700px;
  max-width: 100%;
  margin: 50px auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper {
  padding-right: 25px;
  text-align: right;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch {
  position: relative;
  width: 80px;
  margin: 0 0 0 auto;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch svg {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  fill: var(--color-text-secondary);
  stroke: var(--color-text-secondary);
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch span {
  display: inline-block;
  margin-left: 15px;
  text-transform: uppercase;
  color: var(--color-text-secondary);
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch .lang-dropdown, .lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch .lang-dropdown li {
  list-style: none;
  margin: 0;
  padding: 5px 0;
  text-align: left;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch .lang-dropdown {
  display: none;
  position: absolute;
  z-index: 10;
  width: 100px;
  margin-top: 10px;
  background-color: var(--color-white);
  border-radius: 3px;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch.active .lang-dropdown {
  display: block;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch .lang-dropdown li {
  padding: 5px 10px;
}
.lang-wrapper .lang-inner-wrapper .lang-switch-wrapper .lang-switch .lang-dropdown li:not(:last-child) {
  border-bottom: 1px solid var(--color-grey);
}
h1 {
  text-align: center;
  font-family: 'Alata', sans-serif;
  font-size: calc(2rem + 2vw);
  line-height: calc(1rem + 4vw);
  margin: 0 0 15px 0;
  color: var(--color-header);
}

p {
  margin-top: 0;
  text-align: center;
  font-family: 'Open Sans', sans-serif ;
  font-size: calc(1rem + 0.5vw);
  color: var(--color-text);
}

.form-inner-wrapper {
  padding: 50px 15px;
  position: relative;
}

.copy-feedback {
  position: absolute;
  top: 50px;
  right: 17px;
  transition: top 300ms ease-in;
}
.copy-feedback p {
  margin: 0;
  text-align: right;
  color: var(--color-success);
  font-family: 'Alata', sans-serif;
  font-size: calc(0.8rem + 0.5vw);
}

.form-top-section {
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  position: relative;
  border-radius: 5px;
}

.copy-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  padding: 18px;
  display: flex;
  cursor: pointer;
  background-color: var(--color-grey);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.has-textarea .copy-wrapper {
  top: 4px;
  bottom: 13px;
}
.copy-wrapper img {
  width: 100%;
}

.form-top-section input[type='text'] {
  width: 100%;  
  padding: 30px 80px 30px 45px;
  font-family: 'Alata', sans-serif;
  font-size: calc(1rem + 1vw);
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  color: var(--color-text-field);
  background-color: var(--color-grey);
  letter-spacing: -2px;
}
.form-top-section textarea {
  width: 100%;  
  padding: 15px 80px 15px 25px;
  font-family: 'Alata', sans-serif;
  font-size: calc(0.6rem + 1vw);
  border: none;
  height: 200px;
  line-height: calc(1.6rem + 1vw);
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  color: var(--color-text-field);
  background-color: var(--color-grey);
  letter-spacing: -2px;
  resize: vertical;
}
.password_strength {
  position: absolute;
  bottom: 0;
  width: 0;
  box-sizing: border-box;
  padding: 3px 0;
  cursor: not-allowed;
  background-color: transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-transition: width 300ms ease-in;
  -moz-transition: width 300ms ease-in;
  -ms-transition: width 300ms ease-in;
  -o-transition: width 300ms ease-in;
  transition: width 300ms ease-in;
}
.has-textarea .password_strength {
  bottom: 4px;
  border-radius: 5px;
}
.advance-settings-wrapper {
  margin: 5px 0;
}
.advance-settings-wrapper label {
  display: flex;
  place-content: flex-end;
}
.advance-settings-wrapper label .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  border: none;
  background-color: var(--color-white);
  border-radius: 30px;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.advance-settings-wrapper label.selected .switch {
  background-color: var(--color-success);
}

.advance-settings-wrapper label .switch .switch-lever {
  display: block;
  width: 15px;
  height: 15px;
  margin: 3px 0 3px 4px;
  background-color: var(--color-grey-dark);
  border-radius: 50%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.advance-settings-wrapper label.selected .switch .switch-lever {  
  margin-left: 30px;
  margin-top: 2px;
  background-color: var(--color-white);
}

.advance-settings-wrapper label input[type="checkbox"] {
  margin-left: -8px;
  opacity: 0;
}

.advance-settings-wrapper label span:not(.switch) {
  font-family: 'Alata', sans-serif;
  text-transform: uppercase;
  font-size: 0.9rem;
  color: var(--color-text);
}

.form-footer-section{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(270px,1fr));
  margin: 55px 0;
}

.form-footer-section div:first-child {
  margin: auto 0;
  position: relative;
}

.form-footer-section .length-display {
  position: absolute;
  top: 33px;
  right: 34px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--color-primary);
  text-align: center;
  padding: 9px 0;
  box-sizing: border-box;
  font-family: 'Alata', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-text-primary);
}

.form-footer-column {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(162px,1fr));
}

.form-footer-column.advance-option {
  display: none;
}

.form-footer-column.advance-option.advance-active {
  display: grid;
}

.form-footer-main-column label {
  display: block;
  font-family: 'Alata', sans-serif;
  font-size: 1.2rem;
  color: var(--color-text);
}

.form-footer-main-column input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 80%;  
  border-radius: 10px;
}

.form-footer-main-column input[type=range]:focus {
  outline: none;
}

.form-footer-main-column input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 17.3333px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--color-primary);
  border: 6px solid var(--color-primary);  
  border-radius: 10px;
}

.form-footer-main-column input[type=range]::-webkit-slider-thumb {
  border: 3px solid var(--color-primary);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--color-bg-primary);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -17px;
}

.form-footer-main-column input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--color-primary);
}

.form-footer-main-column input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--color-primary);
  border-radius: 10px;
  border: 6px solid var(--color-primary);
}

.form-footer-main-column input[type=range]::-moz-range-thumb {
  border: 3px solid var(--color-primary);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: var(--color-bg-primary);
  cursor: pointer;
  margin-top: -14px;
}

.form-footer-main-column input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--color-primary);
  color: transparent;
}

.form-footer-main-column input[type=range]::-ms-fill-lower {
  background: var(--color-primary);
}

.form-footer-main-column input[type=range]::-ms-fill-upper {
  background: var(--color-primary);
  border: 0.2px solid #010101;
}

.form-footer-main-column input[type=range]::-ms-thumb {
  border: 3px solid var(--color-primary);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: var(--color-bg-primary);
  cursor: pointer;
  margin-top: -14px;
}

.form-footer-main-column input[type=range]:focus::-ms-fill-lower {
  background: var(--color-primary);
}

.form-footer-main-column input[type=range]:focus::-ms-fill-upper {
  background: var(--color-primary);
}
input[type="range" i] {
  background: var(--color-primary);
}

.form-footer-column label {
  margin: 10px 0;
  position: relative;
  cursor: pointer;
}

.form-footer-column label input[type="checkbox"] {
  opacity: 0;
}

.form-footer-column label span{
  padding-left: 30px;
}

.form-footer-column label .check {
  padding: 0;
  display: block;
  position: absolute;
  top: 26px;
}

.form-footer-column label:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: var(--color-bg-primary);
  border: 2px solid var(--color-primary);
  border-radius: 5px;
  display: block;
  z-index: -1;
}

.form-footer-column div label:before {
  content:unset;
}

.form-footer-column label.selected:before {
  background: var(--color-primary);
}

.form-footer-column label .check:before {
  content: "";
  width: 4px;
  height: 5px;
  top: -12px;
  left: 8px;
  transform: rotate(-46deg);
  background: var(--color-bg-primary);
  position: absolute;
  opacity: 0;
}

.form-footer-column label .check:after {
content: "";
width: 4px;
height: 15px;
top: -18px;
left: 15px;
transform: rotate(54deg);
background: var(--color-bg-primary);
position: absolute;
opacity: 0;
}

.form-footer-column label.selected .check:before {
opacity: 1;
}

.form-footer-column label.selected .check:after {
opacity: 1;
}
.form-footer-column input[type="text"] {
  width: 100%;
  padding: 8px 15px;
  outline: none;
  box-sizing: border-box;
}
.form-button {
  text-align: center;
}
.form-button input[type="submit"] {
  -webkit-appearance: none;
  background-color: var(--color-grey-light);
  padding: 10px 35px;
  font-family: 'Alata', sans-serif;
  font-size: calc(1rem + 0.5vw);
  border-radius: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--color-text-field);
}
.form-button input[type="submit"]:hover {
  background-color: var(--color-primary);
  color: var(--color-text-primary);
}
.form-button input[type="submit"]:active{
  margin-top: 1px;
}
.error-wrapper {
  padding: 5px 0;
  background: #f19292;
  margin-top: 5px;
  border: 1px solid #b35555;
  border-radius: 5px;
  display: none;
}
.error-wrapper p{
  margin: 0;
  color: #3e0505;
  font-family: 'Alata', sans-serif;
  font-size: calc(0.6rem + 0.5vw);
}
.extended_section {
  padding-bottom: 20px;
}
.extended_section .faq_wrapper h2 {
  font-size: calc(1rem + 0.5vw);
  font-family: 'Alata', sans-serif;
  margin-bottom: 10px;
  text-align: center;
  color: var(--color-text-secondary);
}
.extended_section .faq_wrapper .faq_list {
  list-style: none;
  margin: 0;
}
.extended_section .faq_wrapper .faq_list .faq_item {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-border);
}
.extended_section .faq_wrapper .faq_list .faq_item .question {
  font-size: 1.3rem;
  font-family: 'Alata', sans-serif;
  margin-bottom: 15px;
  font-weight: normal;
  color: var(--color-primary);
}
.extended_section .faq_wrapper .faq_list .faq_item .answer {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.9;
  margin-bottom: 20px;
  color: var(--color-text-secondary);
}
.extended_section .faq_wrapper .faq_list .faq_item .answer a {
  color: var(--color-link);
  text-decoration: none;
}
.extended_section .faq_wrapper .faq_list .faq_item .answer a:hover {
  border-bottom: 3px double var(--color-link);
}
@media only screen and (max-width: 700px ){
  h1 {
    line-height: calc(2rem + 4vw);
  }
  .form-top-section textarea {
    font-size: calc(0.7rem + 1vw);
  }
  .form-footer-section {
    display: block;
  }
  .form-footer-main-column, .form-footer-column {
    width: 100%;
  }
  .form-footer-section .form-footer-main-column:first-child {
    margin: 0px 0px 35px 0px;
  }
  .form-footer-main-column input[type=range] {
    width: 95%;
  }
  .form-footer-section .length-display {
    right: 0;
  }
}